<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="purple"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open data
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        dense
      >
      <template v-slot:item.creado="{item}">
          {{getDateTime(item)}}
        </template>
      </v-data-table>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
    props:{
        items:{
            type:Array
            ,default: () =>  []
        },
        itemsPerPage:{
            type: Number,
            default: 10
        }
    },
    data:()=>({
        sheet:false,
        loading:false,
        headers:[
            {text:'Id',value:'id'},
            {text:'Nombre',value:'deviceName'},
            {text:'Imei',value:'uniqueid'},
            {text:'Lat',value:'latitude'},
            {text:'Lng',value:'longitude'},
            {text:'Fecha',value:'creado'},
            {text:'Curso',value:'course'},
          ]
    }),
    methods:{
      getDateTime(item){
          return this.$func.getFullDateString(item.creado);
      },
    }
}
</script>

<style>

</style>